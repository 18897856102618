.borderGap {
  border-bottom: 1px dashed #e5eaee;
  margin-bottom: 20px;
  width: 100%;
}

.site-name {
  color: #fff;
  margin-top: 10px;
  margin-left: 10px;
  font-weight: 500;
  font-size: 15px;
}

@media (min-width: 992px) {
  .header-fixed.subheader-fixed.subheader-enabled .wrapper {
    padding-top: 70px;
  }
}

@media (max-width: 991.98px) {
  .subheader-enabled .content {
    padding-top: 15px;
  }
}

.aside-minimize .brand .brand-logo span {
  display: none;
}

.site_name_login {
  text-transform: uppercase;
  font-weight: 800;
  color: #2f3044;
  margin-top: -5px !important;
}

.login-label {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #000000 !important;
}

/* Design Dash Board */

.shipping-card {
  background-color: #4a7dff;
  padding-top: 30px;
  padding-bottom: 5px !important;
}

.CM-card {
  background-color: #40e0d0;
  /* padding-top: 30px; */
  padding-bottom: 5px !important;
  position: relative;
}

.CM-card-4TH {
  background-color: #93b1e1;
  padding-top: 20px;
  padding-bottom: 0px !important;
}

.shipping-card h2 {
  color: white;
  font-weight: 800;
  padding-top: 15px;
}

.second-color {
  background-color: #3699ff;
}

.shipping-card p {
  color: white;
  font-size: 12px;
}

.spacer {
  padding-left: 30px;
}

/* Design Dash Board */

/* custom form design */

.formHeight {
  height: 30px !important;
  background: #fff;
  font-size: 10px !important;
  font-weight: 400;
}

.css-1fhf3k1-control {
  height: 30px !important;
  min-height: 30px !important;
}

.form-input-white {
  background: #fff;
}

.formFont {
  font-size: 10px !important;
  margin-bottom: 0px !important;
}

button.saveButton {
  height: 29px;
  line-height: 10px;
  background-color: #789dfe !important;
  font-weight: 400;
  border-radius: 3px;
}

.cancelButton {
  background-color: #e5eaee !important;
  /* height: 29px; */
  line-height: 12px !important;
  color: #464e5f !important;
  font-weight: 400;
  border-radius: 3px;
}

.formSelect {
  height: 30px;
  font-size: 10px !important;
  background: #f3f6f9;
  font-weight: 500;
  padding-top: 5px;
}

.chooseFileText {
  color: #b5b5c3;
}

.fileInput {
  border-bottom-right-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

input.fileInput,
span#inputGroupPrepend {
  height: 30px;
}

/* DashBoard Main Page Design Starts */

/* DashBoard Main Page top card by efaj starts */

.top_card {
  margin-top: 5px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: #fff;
  padding: 10px;
  border-radius: 13px;
  overflow: hidden;
  color: black;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.top_card_dashboardChart {
  margin-top: 5px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: rgb(225, 249, 243);
  padding: 10px;
  /* border-radius: 13px; */
  overflow: hidden;
  color: black;
  position: relative;
  text-align: center;
}

.top_card_dashboardChart_cfo {
  margin-top: 3px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: #fff;
  padding: 10px;
  /* border-radius: 13px; */
  overflow: hidden;
  color: black;
  /* position: relative; */
  text-align: center;
}

.sub_top_card_dashboardChart {
  box-shadow: 0px 3px 6px #2c28281c;
  background: #fff;
  padding: 5px;
  overflow: hidden;
  color: black;
  position: absolute;
  text-align: center;
  border: 1px solid #02a423;
  top: 200px;
  left: 390px;
  font-size: 11px;
}

.sub2_top_card_dashboardChart {
  margin-top: 43px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: #fff;
  padding: 5px;
  overflow: hidden;
  color: black;
  position: absolute;
  text-align: center;
  border: 1px solid #543f05;
  top: 188px;
  left: 390px;
  font-size: 11px;
}

.top_card2 {
  box-shadow: 0px 3px 6px #2c28281c;
  background: rgb(6, 11, 103);
  padding: 15px;
}

.top_card3 {
  padding: 10px;
  background: #0b8794;
}

.top_card_CM {
  padding: 10px;
  background: #19195D;
}

.top_card_CM_Mobile {
  padding: 10px;
  background: #19195D;
}

.top_card4 {
  padding: 2px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: #0b8794;
  /* font-family: fantasy; */
}

.topCard_img img {
  width: 45px;
}

.salesDashboardtext {
  color: #c1c9d6;
}

.dashboardSalesCardDataColor {
  font-size: 20px;
  color: #000000;
  font-weight: 500;
}

.sales_card {
  margin-top: 5px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: #fff;
  overflow: hidden;
  color: black;
  padding: 17px;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.pie_chart_card {
  margin-top: 5px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: #fff;
  overflow: hidden;
  color: black;
  padding: 08px;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.acheivement_chart_card {
  margin-top: 5px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: #fff;
  overflow: hidden;
  color: black;
  padding: 27px;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.circ {
  height: 10px !important;
}

/* DashBoard Main Page top card by efaj ends */

.dashboard__pb {
  padding-bottom: 30px;
}

.paid__widget {
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 10px;
  background: #f64e60;
  overflow: hidden;
  color: #ffff;
  padding: 20px;
  overflow: hidden;
}

.widget__right {
  padding-top: 10px;
}

.widget__right p {
  color: #ffff;
  font-weight: 500;
  font-size: 13px !important;
  padding-top: 7px;
}

.widget__right h4 {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

.chart__box {
  box-shadow: 0px 2px 6px #2c28281c;
  border-radius: 10px;
  padding-bottom: 10%;
  overflow: hidden;
  height: 380px;
}

.chart__box p {
  padding-top: 10px;
  font-size: 11px !important;
  padding-left: 10px;
}

.chart__box h4 {
  font-size: 14px !important;
  padding-left: 10px;
  color: #172b4d;
}

.paid__widget.one {
  background-color: #6993ff;
}

.paid__widget.two {
  background-color: #1bc5bd;
}

.paid__widget.three {
  background-color: #ffa800;
}

.dough__main {
  padding-top: 30px;
}

.doug__left {
  float: left;
  width: 52%;
  float: left;
  width: 52%;
  padding-top: 25px;
}

.doug__right {
  float: left;
  width: 40%;
  padding-top: 25px;
}

.color__circle {
  display: inline-block;
}

.stat__1 {
  position: relative;
}

.stat__1::before {
  content: "";
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: black;
  position: absolute;
  top: 10px;
  left: 0px;
  border: 3px solid #feb969;
  content: "";
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 9px;
  left: -4px;
  border: 3px solid #feb969;
}

.border__orange {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: black;
  position: absolute;
  top: 10px;
  left: 0px;
  border: 3px solid #2dce98;
}

.border__blue {
  border: 3px solid #1f50b1;
}

.border__red {
  border: 3px solid #f53c56;
}

.dough__list {
  color: #4d4f5c;
  font-size: 11px;
  font-weight: 600;
  margin-left: 3px;
  font-family: "poppins";
}

.list__1 {
  margin-right: 25px;
}

.list__2 {
  margin-right: 15px;
}

.list__3 {
  margin-right: 70px;
}

.list__4 {
  margin-right: 67px;
}

.dough__num {
  font-weight: 500;
}

.widget__left img {
  width: 30px;
}

.widget__left.three img {
  width: 20px;
}

/* DashBoard Main Page Design Ends */

/* login page  */

.form-control:focus {
  color: #000000 !important;
}

.stat__2 {
  position: relative;
}

.stat__2::before {
  content: "";
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 9px;
  left: -4px;
  border: 3px solid #2dce98;
}

.stat__3 {
  position: relative;
}

.stat__3::before {
  content: "";
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 9px;
  left: -4px;
  border: 3px solid #1f50b1;
}

.stat__4 {
  position: relative;
}

.stat__4::before {
  content: "";
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 9px;
  left: -4px;
  border: 3px solid #f53c56;
}

a#kt_login_forgot {
  color: #3699ff !important;
  font-weight: 600;
}

button#kt_login_signin_submit {
  box-shadow: #00000029 !important;
  margin: auto;
}

.heading {
  color: #160637;
  font-size: 30px !important;
}

span.loginText {
  font-weight: 600;
}

a {
  color: #4174aa !important;
  font-size: 12px;
}

img.loginpageLogo {
  width: 180px;
}

.login.login-1 .login-aside {
  height: 100vh;
}

.loginForm input.form-control {
  background: #eef1f5 !important;
  height: 36px !important;
  border: 1px solid #eef1f5;
  box-shadow: 0px 1px 5px #00000010 !important;
}

h1.font-size-h1.heading {
  text-align: center;
  font-weight: 500;
  /* margin-bottom: 20px; */
}

.heading {
  font-size: 22px !important;
}

.overlay__caption {
  background-color: #007bff;
  color: white;
  border-radius: 9px;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.2s;
  position: relative;
  border-radius: 5px;
}

.overlay__caption:hover {
  color: #fff;
  /* box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15); */
}

.overlay__caption:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #007bff;
  opacity: 0;
  transition: all 0.3s, opacity 0.2s;
  transform: scaleY(0);
  transform-origin: bottom;
}

.overlay__caption:hover:before {
  transform: scaleY(1);
  opacity: 0.25;
}

.login.login-1 .login-aside {
  width: 100%;
}

.loginForm input.form-control {
  background: #eef1f5 !important;
  height: 36px !important;
  border: 1px solid #95989a4d;
  box-shadow: 0px 1px 5px #00000029 !important;
}

.loginForm input#formBasicChecbox {
  box-shadow: 0px 3px 5px #00000021 !important;
  border: none !important;
}

.flex-row-fluid.d-flex.flex-column.position-relative.loginPage {
  max-width: 50% !important;
}

.login-aside.d-flex.flex-row-auto.loginBg {
  max-width: 50%;
}

input.form-control.loginFormInput.emailinput.h-auto.py-5.px-6.is-valid {
  width: 300px !important;
}

.login-signin {
  width: 400px;
}

img.loginpageLogo {
  margin: auto;
  display: block;
}

/* Table */

button.btn.approve {
  background: #eee5ff;
  color: #8950fc;
  border-color: #eee5ff;
  font-weight: 500;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2;
}

i.editIcon {
  background: #f3f6f9;
  padding: 8px;
  border-radius: 5px;
  color: #3699ff;
}

i.deleteIcon {
  background: #f3f6f9;
  padding: 8px;
  border-radius: 5px;
  color: #bc0f0f;
}

i.password {
  border-radius: 5px;
  color: #3699ff;
}

/* i.editIcon:hover {
      border: 2px solid #8950fc;
      padding: 8px;
    } */

input.form-check-input.position-static {
  background: #f3f6f9;
}

.circulerBar {
  width: 39px;
}

.voyageTable td {
  color: #464e5f;
  font-size: 13px;
}

.voyageTable th {
  color: #b5b5c3;
  font-size: 12px;
  text-transform: uppercase;
}

h1.tableheading {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  margin-top: 5px;
}

i.tableFilter {
  color: #3699ff;
}

.input-area-add .float-left {
  width: 80%;
}

.input-area-add .float-right {
  width: 20%;
}

.input-area-add input,
.input-area-add select {
  border-radius: 0px !important;
}

.btn-modal-close {
  position: absolute;
  right: -16px;
  padding-right: 20px;
  cursor: pointer;
  top: -10px;
  background: #ffffffed;
  border-radius: 20px;
  width: 36px;
  height: 36px;
  padding: 12px;
  box-shadow: 1px 2px 30px grey;
}

.btn-default {
  position: relative !important;
}

.btn-default i {
  width: 9px;
  height: 9px;
  position: absolute;
  right: 10px;
  bottom: 7px;
  font-size: 14px !important;
}

button.btn.btn-default {
  border: 1px solid #e5eaee;
}

.btn.btn-default i {
  color: #d9e0e6;
}

.btn-modal-close i {
  margin: -1px;
  margin-left: 1px;
  margin-top: -2px;
}

.input-area-add .btn-default,
.input-area-add .btn-default:hover {
  border-radius: 4px !important;
  /* background: white; */
  /* border-color: #ecf0f3; */
  /* border-left: 0px !important; */
  margin-left: -3px;
  background: #fff;
  border: 1px solid #e5eaee !important;
  height: 30px;
  background: #fff !important;
}

.tbl-survey {
  /* width: 74.5% !important; */
  width: 100% !important;
  margin: 10px 0px 10px 0px;
}

.tbl-survey thead {
  background: red !important;
}

.tbl-survey th {
  background: #789dfe38;
}

/* basic form */

.css-1okebmr-indicatorSeparator {
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}

.css-yk16xz-control {
  background: #fff !important;
  height: 30px !important;
  min-height: 30px !important;
  border: 1px solid #e5eaee !important;
}

.css-1pahdxg-control {
  background: #fff !important;
  height: 30px !important;
  min-height: 30px !important;
  border: 1px solid #e5eaee !important;
}

.css-g1d714-ValueContainer {
  padding: 3px !important;
  /* margin-top: -5px !important; */
}

.aside-menu .menu-nav>.menu-section {
  margin: 0px 0 0 0 !important;
  height: 10px !important;
}

.aside-menu .menu-nav>.menu-item>.menu-link {
  padding: 5px 20px !important;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link {
  min-height: 30px !important;
}

.css-26l3qy-menu {
  padding: 0px !important;
}

.aside-menu .menu-nav>.menu-item>.menu-heading,
.aside-menu .menu-nav>.menu-item>.menu-link {
  min-height: 36px !important;
}

.aside .aside-menu .menu-nav {
  width: auto !important;
  /* width: 1800px; */
}

.css-1laa021-a11yText {
  padding: 0px !important;
}

.css-1wa3eu0-placeholder {
  top: 42% !important;
  font-size: 10px !important;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  margin-top: -5px !important;
}

.css-1uccc91-singleValue {
  top: 35% !important;
  font-size: 10px !important;
}

.searchInput {
  background: #fff !important;
  height: 30px !important;
  border: 1px solid #e5eaee !important;
  box-shadow: none !important;
}

.searchInput i.flaticon-search {
  padding-left: 5px !important;
}

button.MuiButtonBase-root.MuiIconButton-root.searchPlaceholder {
  padding: 0px !important;
  padding-top: 2px !important;
}

input.MuiInputBase-input {
  margin-left: 10px;
}

.table.table-head-custom thead th,
.table.table-head-custom thead tr {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border-bottom: 1px solid #ecf0f3 !important;
  padding: 8px;
}

.table.table-head-custom tbody th,
.table.table-head-custom tbody tr {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border-bottom: 1px solid #ecf0f3 !important;
  padding: 8px;
}

.table.table-head-custom-print thead tr th,
.table.table-head-custom-print thead tr {
  font-size: 25px;
  border: 1px solid #000 !important;
  padding: 10px;
  height: 20px;
}

.table.table-head-custom-print tbody th,
.table.table-head-custom-print tbody tr {
  font-size: 25px;
  border: 1px solid #000 !important;
  padding: 10px;
  height: 20px;
}

.user-list-table td,
th {
  border-bottom: 1px solid #ecf0f3 !important;
}

.table {
  margin-bottom: 2px !important;
}

.table.table-head-custom thead td {
  color: #464e5f;
  font-weight: 400 !important;
}

.table.table-head-custom thead th {
  color: #b5b5c3;
  font-weight: 600;
}

.table.table-head-custom-print thead td {
  color: #000;
  font-size: 25px;
  font-weight: 900 !important;
}

.table.table-head-custom-print tbody tr td {
  color: #000;
  font-size: 25px;
  border: 1px solid #000 !important;
  font-weight: 900 !important;
}

.table.table-head-custom-print thead th {
  color: #000;
  font-size: 25px;
  font-weight: 900;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.modal-body {
  padding: 0.75rem;
  /* padding-top: 0px !important; */
}

input[type="file"] {
  border: 1px solid #e5eaee;
  border-radius: 4px;
}

.MuiPaper-rounded {
  border-radius: 6px !important;
}

.custome-datepicker {
  position: relative;
}

i.fas.fa-calendar-alt {
  position: absolute;
  top: 29px;
  right: 20px !important;
  font-size: 10px !important;
}

.page-item.active .page-link {
  background-color: #3699ff;
  border-color: #3699ff;
  color: #fff !important;
  z-index: 0;
  position: absolute;
  color: #abb5be;
  /* left: 249px; */
}

input.date-picker,
input.react-datepicker-ignore-onclickoutside {
  width: 100% !important;
  border-radius: 4px;
  height: 30px;
  border: 1px solid #e5eaee;
  font-size: 10px !important;
  color: #464e5f;
  padding-left: 10px;
  position: relative !important;
}

.loginBg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

button#kt_login_signin_submit {
  width: 142px;
}

/* line chart */

.line-chartsection {
  border-radius: 12px;
}

.pointer {
  cursor: pointer;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.status-list {
  background: aliceblue;
  padding: 4px;
  margin-top: 30px;
}

.float-left.total {
  width: 21%;
}

.float-left.balance {
  width: 16%;
}

/* table form */

.row.mb-5.table-form {
  align-items: center;
}

/* dashboard table */

h1.sales-payment-heading {
  font-size: 20px;
  color: #212121;
}

table.table.table.dashboard-table th,
td {
  font-weight: 600;
  color: #212121 !important;
  text-transform: capitalize;
}

p.dashboard-table-text {
  font-weight: 600;
  background-color: #f3f6f9;
}

.btn.invoice-btn {
  border-color: #789dfe;
  background: #789dfe;
}

.btn.invoice-btn:hover {
  border-color: #789dfe !important;
  background: #789dfe !important;
}

button.btn.text-white.float-right.invoice-btn {
  padding: 5px 10px !important;
  border-radius: 4px !important;
}

p.show-value-text {
  color: #000000 !important;
}

.attachment-file input {
  /* color: #b5b5c3; */
  font-size: 12px !important;
}

::-webkit-file-upload-button {
  background: #fff;
  border: 1px solid #e5eaee;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px !important;
  padding: 5px;
  /* color: #b5b5c3; */
}

/*  */

a._2pGos._3CDiP._2RWmX,
a._2pGos._3CDiP {
  background: #e5e5e5 !important;
  line-height: 10px !important;
  color: #464e5f !important;
  font-weight: 400;
  border-radius: 3px !important;
}

._hsN1w {
  line-height: 10px !important;
  background-color: #789dfe !important;
  border: 1px solid #789dfe !important;
  font-weight: 400;
  border-radius: 3px !important;
  color: #fff !important;
}

._3uApM {
  margin-top: -5px !important;
  margin-left: 25px;
}

h3 {
  font-size: 18px !important;
}

._1hzhf {
  font-size: 14px;
}

._2Jtxm._35Ago ._2kL0S {
  margin-left: -140px;
}

._2Jtxm {
  margin-left: -66px;
}

._1Lo2h {
  width: 109%;
}

/* Certificate create */

.card-header.certificate-cardheader {
  min-height: 0px !important;
}

.certificate-card-body {
  padding: 0rem 2.25rem !important;
}

.header.header-fixed {
  height: 50px !important;
}

.header-fixed.subheader-fixed.subheader-enabled .wrapper {
  padding-top: 54px !important;
}

.custome-addnew-btn {
  height: 29px;
  padding: 5px;
}

/* item list start */

i.item-list-icon {
  font-size: 12px !important;
}

.itemtable td {
  font-weight: 400 !important;
}

i.editIcon.item-list-icon {
  padding: 5px;
  margin-left: 5px;
}

button.item-list-btn {
  height: 33px;
  border-radius: 4px;
  background: #789dfe !important;
  border: 1px solid #789dfe !important;
}

/* item add */

button.item-add-btn {
  border: 1px solid #afafaf !important;
  background: #e4ebff !important;
}

button.item-add-save {
  padding-left: 35px;
  padding-right: 35px;
  background: #1bc5bd !important;
  border: #1bc5bd !important;
  border-radius: 4px !important;
}

table.table.table.item-add-table th,
tr,
td {
  border-top: none !important;
  border-bottom: none !important;
  font-weight: 400 !important;
}

/* booking entry */

.booking-entry-input.form-group {
  margin-left: -13px;
  width: 112% !important;
}

.input-group {
  width: 112% !important;
}

.booking-btn {
  background-color: #007bff !important;
  line-height: 12px !important;
  border-radius: 3px;
}

.MuiPaper-elevation1 {
  box-shadow: none 0px !important;
}

.booking-list-btn {
  background: #fff2d8 !important;
  border: 1px solid #fff2d8 !important;
}

.form-group label {
  font-size: 12px !important;
  margin-bottom: 0px !important;
}

span#inputGroupPrepend {
  height: 30px !important;
  border-left: 0px !important;
}

.MuiPaper-root.searchInput.MuiPaper-elevation1.MuiPaper-rounded {
  box-shadow: none !important;
}

.certificate-add-btn.btn-sm {
  line-height: 1.15 !important;
}

.row.custom-modal {
  width: 1063px;
}

hr.hr-margin {
  margin-top: 8px !important;
}

/* userlist modal */

button.modal-button {
  border: none !important;
  background: none !important;
}

/* cerificate list */

button.certificate-done {
  background: #8ec7ff !important;
  border: #8ec7ff !important;
}

.certificate-due {
  background: #678db2 !important;
  border: #678db2 !important;
}

.certificate-lis-btn {
  padding: 3px 15px !important;
  border-radius: 2px !important;
  width: 75px !important;
  border: none !important;
}

.certificate-edit-icon {
  background: #e7f3fe !important;
  padding: 5px;
  border-radius: 2px;
  color: #3699ff;
  font-size: 11px;
}

.due-days h6 {
  font-size: 11px !important;
  text-transform: uppercase;
  position: absolute;
  top: 6px;
  left: 29px;
}

.due-days {
  position: relative;
}

p.certificate-list-text {
  width: 101px;
  font-size: 12px !important;
  font-weight: bold;
}

.certificate-due-0 {
  background: #ea673e !important;
}

.certificate-due-l-30 {
  background: #8ec7ff !important;
}

.certificate-due-30-60 {
  background: #678db2 !important;
}

.certificate-due-g-60 {
  background: #8af2c0 !important;
}

/* expense addition-deduction */

/* .MuiPaper-root.makeStyles-root-1.MuiPaper-elevation1.MuiPaper-rounded {
    
      box-shadow: none !important;
      border: 1px solid #ccc !important;
  } */

/* certificate color picker */

input.color-picker {
  /* margin-bottom: 7px; */
  height: 24px;
  width: 24px;
  border-radius: 3px;
  /* margin-left: 12px; */
}

.card-top-border {
  border-top: 3px solid #3699ff !important;
  border-radius: 0px !important;
}

.card-top-border1 {
  border-top: 3px solid #eb6f09 !important;
  border-radius: 0px !important;
}

.card-bottom-border {
  border-bottom: 3px solid #eb6f09 !important;
  border-radius: 0px !important;
}

.card-top-border-success {
  border-top: 3px solid #1bc5bd !important;
  border-radius: 0px !important;
}

.card-top-border-info {
  border-top: 3px solid #3699ff !important;
  border-radius: 0px !important;
}

.card-top-border-error {
  border-top: 3px solid #ff9da7 !important;
  border-radius: 0px !important;
}

.MuiInputBase-root.custome-purchase-search {
  width: 85%;
}

button.searchPlaceholder.purchaseSearch {
  right: 21px !important;
}

.purchaseSearch {
  margin-left: 20px !important;
}

.custome-border-left {
  min-width: 14%;
}

.custome-border-design {
  width: 100%;
  border-bottom: 2px solid lightgray;
  margin-left: 13px;
  margin-bottom: 10px;
}

/* navbar */

.aside-enabled.subheader-fixed .subheader {
  left: 255px;
}

.header-fixed.subheader-fixed .subheader {
  position: fixed;
  height: 54px;
  top: 50px !important;
  left: 0;
  right: 0;
  transition: top 0.3s ease;
  z-index: 95;
  box-shadow: 0 10px 30px 0 rgb(82 63 105 / 8%);
  background-color: #fff;
  border-top: 1px solid #ebedf3;
  margin: 0;
}

.subheader {
  display: flex;
  align-items: center;
}

.dashboard-cardsection {
  padding-top: 43px !important;
}

.dashboard-navbar-left {
  width: 50% !important;
  float: left !important;
}

.dashboard-navbar-right {
  float: right !important;
  width: 47% !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.card.card-custom>.card-header {
  min-height: 45px !important;
}

/* .card.card-custom > .card-body {
    padding: 2rem 2rem !important;
  } */

/* .aside {
    width: 230px !important;
  } */

.header-fixed.subheader-fixed .header {
  background: #3699ff;
}

.top-search-bar {
  background: #0000001a;
}

.top-search-bar:hover {
  background: #007bff !important;
}

@media (min-width: 992px) {
  .aside-minimize:not(.aside-minimize-hover) .brand {
    display: none !important;
  }
}

input.bulk-file-input {
  display: block;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
  border: 1px solid #ccc !important;
}

.searchPlaceholder:hover {
  background: none !important;
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
}

i.flaticon-search {
  margin-bottom: 0px !important;
}

.supplier-search {
  position: relative !important;
}

button.searchPlaceholder {
  position: absolute !important;
  right: 8px !important;
}

.viewIcon {
  background: #e7f3fe;
  padding: 5px;
  border-radius: 5px;
  color: #3699ff;
  font-size: 10px;
}

.custom-border {
  border-bottom: 2px solid #ecf0f3 !important;
  width: 107%;
  margin-left: -47px;
}

.supplier-table td {
  padding: 5px 0px !important;
}

i.fas.fa-trash-alt.viewIcon {
  margin-left: 15px;
}

h5.supplier-detail-date {
  font-size: 13px !important;
}

h6.supplier-detail-text {
  font-size: 11px !important;
}

h6.supplier-modal-header {
  font-weight: 900 !important;
  color: #212529 !important;
  font-size: 16px !important;
}

.supplier-info p {
  font-weight: 500 !important;
  color: #212529 !important;
  line-height: 1.5 !important;
}

.quotation-table td {
  padding: 10px 0px !important;
}

/* employee-list page */
.employee-list-input {
  height: 30px !important;
  padding: 0px !important;
}

.employee-signin {
  margin-left: -11px;
}

.float-left.booking-text {
  width: 8%;
}

.booking-text.float-right {
  width: 92%;
}

.float-left.booking-text.one {
  width: 10%;
}

.booking-text.float-right.one {
  width: 90%;
}

.float-left.booking-text.two {
  width: 16%;
}

.booking-text.float-right.two {
  width: 84%;
}

.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-clean:focus:not(.btn-text),
.btn.btn-clean.focus:not(.btn-text) {
  background-color: transparent !important;
}

.certificate-list-table th {
  font-size: 10px !important;
  font-weight: 400 !important;
}

.certificate-list-table td {
  font-size: 11px !important;
}

h1.headerText {
  font-size: 13px !important;
}

.custome-border {
  position: relative;
}

.custome-border::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 80%;
  background-color: #000000;
  left: 0;
  top: 0;
}

/* booking entry */
.float-left.Rate-commision {
  width: 13%;
  margin-left: 11px;
}

.float-right.Rate-commision {
  width: 85%;
  margin-top: -6px;
}

.float-left.Rate-commision.one {
  width: 7%;
}

.float-right.Rate-commision.one {
  width: 91%;
}

.float-left.Rate-commision.two {
  width: 8%;
}

.float-right.Rate-commision.two {
  width: 90%;
}

input[type="file"] {
  width: 100%;
}

label.form-check-label {
  font-size: 14px;
}

label.form-control.criteriaOptionsInput {
  padding-bottom: 43px;
}

.invoice-price-right-text {
  text-align: right;
  padding: 22px;
  width: 76%;
  font-weight: 900;
  font-size: 16px;
}

/* @media print {
    body { font-size: 20pt }
    td{
      font-size: 20px;
    }
  }
  @media screen {
    body { font-size: 30px }
    td{
      font-size: 20px;
    }
  
  }
  @media screen, print {
    body { line-height: 1.2 }
    td{
      font-size: 20px;
    }
  } */

/* @media print {
  #printDiv,
  #printDiv * {
    visibility: visible;
  }

  #printDiv {
    position: absolute;
    left: 0;
    top: 0;
  }
} */

/* @media print {
    body * {
      visibility: hidden;
    }
    #printDiv,
    #printDiv * {
      visibility: visible;
    }
    #printDiv {
      position: absolute;
      left: 0;
      top: 0;
    }
    #printDivm,
    #printDivm * {
      visibility: visible;
    }
    #printDivm {
      padding: 80px;
      position: absolute;
    }
  } */
th.divisionPos {
  position: absolute;
  width: 15%;
}

th.regionPos {
  position: absolute;
  width: 7%;
  left: 367px;
}

input.fromStyle.form-control {
  height: 30px;
}

.refreshIcon {
  font-size: 24px;
}

.balanceleft {
  float: left;
  width: 50%;
  height: 21px;
  font-size: 14px;
}

.balanceleftmodified {
  float: left;
  width: 23%;
  height: 21px;
  font-size: 14px;
}

.markDetails {
  background-color: #8530161f !important;
}

.btn-group.SalesType {
  height: 40px;
}

label.btn.btn-outline-primary.salsRadious {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.profileScreen.card {
  padding: 40px;
}

.aprovedRow span {
  float: left;
  width: 14%;
}

p.aprovedRow {
  position: relative;
}

span.enroll {
  position: absolute;
  right: 30%;
}

span.total {
  position: absolute;
  right: -83px;
}

span.total {
  position: absolute;
  right: -31px;
}

span.quantity {
  position: absolute;
  right: 7%;
}

span.price {
  position: absolute;
  right: 13%;
}

span.soName {
  position: absolute;
  right: 24%;
}

p.aprovedRow {
  position: relative;
  padding: 21px;
  border: 1px solid #ddd;
  background-color: #fffcf5;
}

span.papproved {
  position: absolute;
  left: 40%;
}

span.psoName {
  position: absolute;
  right: 40%;
}

span.pprice {
  position: absolute;
  right: 23%;
}

span.pquantity {
  position: absolute;
  right: 16%;
}

span.papprovedQuntity {
  position: absolute;
  right: 29%;
}

span.pptotal {
  position: absolute;
  right: 8%;
}

.customepadding {
  height: 41px;
}

.dateWiseReport {
  width: 4000px;
}

.tblFooterback {
  background: #f7f7f7 !important;
}

strong.blue {
  font-size: 12px;
  color: #443b3d;
}

/* .pvtAxisContainer, .pvtVals {
    border: 1px solid #a2b1c6;
    background: #f2f5fa;
    padding: 5px;
    min-width: 20px;
    min-height: 20px;
    display: none;
  }
  .pvtRenderers {
    padding-left: 5px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: none;
  }
  table.pvtTable thead tr th, table.pvtTable tbody tr th {
    background-color: #fff !important;
    border: 1px solid #c8d4e3 !important;
    font-size: 8pt;
    padding: 5px;
  }
  .breadBurnPivot {
    width: 200% !important;
  } */
.achiveLayer span {
  display: block;
  font-size: 14px;
  line-height: 22px;
}

/* td.d1 {
    width: 236px;
  } */

/* datewise */
/* th.enroll {
    position: absolute;
    right: 87%;
  }
  thead.dateThe {
    position: relative;
  }
  th.product_code {
    position: absolute;
    right: 87%;
  }
  th.soName {
      position: absolute;
      right: 89%;
  }
  th.product_name {
    position: absolute;
    right: 83%;
  }
  th.field0 {
    position: absolute;
    right: 80%;
  }
  
  th.field1 {
    position: absolute;
    right: 77%;
  }
  th.field2 {
    position: absolute;
    right: 74%;
  }
  th.field3 {
    position: absolute;
    right:71%;
  }
  th.field4 {
    position: absolute;
    right: 69%;
  }
  th.field5 {
    position: absolute;
    right: 66%;
  }
  th.field6 {
    position: absolute;
    right: 63%;
  }
  th.field7 {
    position: absolute;
    right: 60%;
  }
  th.field8 {
    position: absolute;
    right: 57%;
  }
  th.field9 {
    position: absolute;
    right: 54%;
  }
  th.field10 {
    position: absolute;
    right: 51%;
  }
  th.field11 {
    position: absolute;
    right: 48%;
  }
  th.field12 {
    position: absolute;
    right: 45%;
  }
  th.field13 {
    position: absolute;
    right: 42%;
  } */

.validation-symbol {
  color: red !important;
  font-size: 10px !important;
}

.changeLayout {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
}

.bg-yellow {
  background: #ffa800;
  display: block;
  width: 64%;
  margin-right: 13px;
  padding: 6px;
}

.invoiceInfo {
  flex-basis: 8%;
  font-size: 21px;
  font-family: "Font Awesome 5 Free !important";
}

.bg-yellow2 {
  background: #ffa800;
  flex-basis: 25%;
}

p.invoiceTo {
  font-family: "Font Awesome 5 Free";
  font-weight: 700 !important;
  font-size: 21px !important;
}

.invoice-detail {
  font-size: 14px;
  font-weight: bold;
  font-family: "Font Awesome 5 Free";
}

/* soSecondarySales by efaj */

.Secondary_so_information {
  font-weight: 800;
}

.gutter-b {
  margin-bottom: 10px !important;
}

/* soSecondarySales by efaj */

.dashpic {
  height: 25px;
  width: 25px;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.symbol.symbol-60 .symbol-label {
  width: 35px !important;
  height: 35px !important;
}

.dash-AI-akij img {
  align-items: center;
  height: 85px;
  width: 160px;
  padding: 10px;
}

/* .dash-AI-akij img{
    align-items: center;
    height: 140px;
    width: 140px;
  } */
.cTable {
  height: 100%;
}

/* .table-head-custom tr {
    width: 100%;
    display: inline-table;
    height:60px;
    table-layout: fixed;
      
    }
    
    .table-head-custom{
     height:1000px;
     display: block;
    }
    .table-head-custom tbody{
      overflow-X: scroll;
      height: 1000px;
      width: 100%;
      position: absolute;
    } */

.stickyTable {
  overflow-x: auto;
  height: 500px;
}

.stickyTable table {
  width: 150%;
  table-layout: fixed;
}

.stickyTable table th {
  top: 0;
  position: sticky;
  background-color: #fff;
}

.shortstickyTable {
  overflow-x: auto;
  height: 500px;
}

.shortstickyTable table {
  width: 100%;
  table-layout: fixed;
}

.shortstickyTable table th {
  top: 0;
  position: sticky;
  background-color: #fff;
}

.rmdp-input {
  height: 30px !important;
  width: 240px !important;
}

.collectionDateText {
  font-size: 114px;
}

.udevelopmentText {
  font-size: 17px !important;
  font-weight: 800;
}

.udevelopmentText_bg {
  box-shadow: 0px 2px 2px #0000001c;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  color: black;
  padding: 4px;
  overflow: hidden;
  position: relative;
  text-align: center;
  background-color: rgb(252, 237, 212);
}

.ucase {
  text-transform: lowercase !important;
}

.callITsupport {
  padding-top: 30px;
  font-family: "Ubuntu", sans-serif;
  font-size: 25px;
  font-weight: bold;
  color: #02a423;
  text-align: center;
  letter-spacing: 0px;
  font-family: Noto Sans, sans-serif;
}

.cardcustomheight {
  height: 400px;
}

.dashboardTopCardDataColor {
  color: #ff4401;
}

.loadingcustom {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .piedesign{
    width: 370px;
  } */
.totalCategorytext {
  color: #c33d08;
}

.Acheivement {
  height: 310px !important;
}

.achi {
  height: 200px !important;
}

.tblbor {
  border: 1px solid black;
  border-collapse: collapse;
}

.printtblcustom {
  padding: 80px;
}

.textcustom thead tr th {
  font-size: 40px;
}

.textcustom {
  font-size: 40px !important;
}

.tecus th {
  font-size: 40px;
}

@media (min-width: 320px) {
  .stickyTable table {
    width: 150%;
    table-layout: auto;
  }

  .shortstickyTable table {
    width: 100%;
    table-layout: auto;
  }
}

#kt_header {
  background-color: #3699ff;
}

.spineercolor {
  color: #082d54;
}

@page {
  size: auto;
  margin: 20mm;
}

#image-uploader {
  display: flex;
  padding: 5px 8px;
  border: 3px solid;
  border-radius: 0px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  color: #ffc872;
  align-items: center;
  justify-content: center;
}

/* input:hover{
      transform: scale(1.04);
    }
    input.active{
      background-color: #ffc872;
      color: #242424;
    }
    input span{
      font-weight: normal;
    } */

/* .....progress bar ...... */

/* progress bar - 1*/

.vertical_bar {
  margin-top: 3px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 3px;
  background: #fff;
  color: black;
  padding: 3px;
  position: relative;
  text-align: center;
}

.progress {
  background-color: #f5f5f5;
  border-radius: 3px;
  box-shadow: none;
}

.progress.vertical {
  position: relative;
  width: 50px;
  height: 336px;
  display: inline-block;
  border: 1px solid black;
}

.progress {
  height: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress.vertical>.progress-bar {
  width: 100% !important;
  position: absolute;
  bottom: 0;
}

.progress-bar {
  background-color: #3a2205;
  box-shadow: none;
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #733b09 !important;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

/* progress bar - 2*/

.vertical_bar2 {
  margin-top: 3px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 3px;
  background: #fff;
  color: black;
  padding: 3px;
  position: relative;
  text-align: center;
}

.progress2 {
  background-color: #f5f5f5;
  border-radius: 3px;
  box-shadow: none;
}

.progress2.vertical2 {
  position: relative;
  width: 50px;
  height: 341px;
  display: inline-block;
  border: 1px solid black;
}

.progress2 {
  height: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress2.vertical2>.progress-bar2 {
  width: 100% !important;
  position: absolute;
  bottom: 0;
}

.progress-bar2 {
  background-color: #733b09;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.progress-bar2 {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #0b8793;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.vertical_bar_bottom {
  box-shadow: 0px 3px 6px #2c28281c;
  border: solid #7d4b10 1px;
  /* border-radius: 3px; */
  background: white;
  color: #7d4b10;
  padding: 3px;
  position: relative;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
  margin-top: 0 !important;
}

.vertical_bar_bottom2 {
  box-shadow: 0px 3px 6px #2c28281c;
  border: solid #0b8794 1px;
  /* border-radius: 3px; */
  background: white;
  color: #0b8794;
  /* padding: 3px; */
  position: relative;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
  margin-top: 0 !important;
}

/* money */
.money {
  box-shadow: 0px 3px 6px #2c28281c;
  background: rgb(255, 255, 255);
  text-align: center;
  height: 390px;
  border-radius: 3px;
}

.sub_card {
  border-bottom: 1px solid #f7f7f7;
}

.spanheading {
  font-size: 18px !important;
}

.collColor {
  color: #82ADAD;
}

.sub_card span .text-1st-heading {
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 0;
  color: #733f03;
}

.text-1st {
  font-size: 25px;
  font-weight: 200;
  text-align: center;
  letter-spacing: 0;
  font-family: fantasy;
}

.text-1st2 {
  font-size: 30px;
  font-weight: bold;
  color: #0b8793;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  font-family: fantasy;
}

.sub_card span .text-acheived-heading {
  font-size: 12px;
  font-weight: 800;
  margin-bottom: 0;
  color: #806000;
}

.acheived {
  font-weight: 600;
  color: #806000;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  font-family: fantasy;
}

span.acheived {
  font-size: 14px !important;
}

/* .text-2nd{
    font-family: 'Ubuntu', sans-serif;
    font-size: 30px;
    font-weight: bold;
    color: #174767;
    text-align: center;
    letter-spacing: 0px;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.3), 
      0px -4px 10px rgba(255,255,255,0.3);
    font-family: 'Times New Roman', serif;
  } */

.text-2nd {
  font-size: 25px !important;
  font-weight: 200;
  text-align: center;
  letter-spacing: 0;
  font-family: fantasy !important;
}

/* .text-3rd{
    font-family: 'Ubuntu', sans-serif;
    font-size: 27px;
    font-weight: bold;
    color: #ee0b0b;
    text-align: center;
    letter-spacing: 0px;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.3), 
      0px -4px 10px rgba(255,255,255,0.3);
    font-family: 'Monospace', Lucida;
  
  } */
.sub_card span .text-text-2nd-heading {
  font-size: 12px;
  font-weight: 800;
  margin-bottom: 0;
  color: #666600;
}

.text-3rd {
  font-size: 25px;
  font-weight: 200;
  color: #3c4a55;
  text-align: center;
  letter-spacing: 0;
  font-family: fantasy;
}

.text-5th {
  font-size: 20px;
  font-weight: 200;
  color: #3c4a55;
  text-align: center;
  letter-spacing: 0;
  font-family: fantasy;
}

.totaltargettext {
  color: #733f03;
  font-weight: 550;
}

.dashboardCart {
  background-color: #fdfffe !important;
}

.dashboardCartReport {
  background-color: #f4f6e6 !important;
}

.sms {
  background-color: #f9f9f4 !important;
}

.cusheight {
  height: 31px !important;
}

.moneyheading {
  font-size: 20px !important;
  font-weight: 600;
}

.poppins {
  font-family: "poppins" !important;
}

/* barchart */
.cuswidth {
  width: 770px;
}

.dailysales {
  font-size: 20px !important;
  color: #fff;
}

.borderTableims.table-head-custom thead th,
.borderTableims.table-head-custom thead tr {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border: 1px solid #000 !important;
  padding: 8px;
}

.borderTableims.table-head-custom tbody th,
.borderTableims.table-head-custom tbody tr td {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border: 1px solid #000 !important;
  padding: 8px;
}

.borderTableims.table-head-custom-print thead tr th,
.borderTableims.table-head-custom-print thead tr {
  font-size: 25px;
  border: 1px solid #000 !important;
  padding: 10px;
  height: 20px;
}

.borderTableims.table-head-custom-print tbody th,
.borderTableims.table-head-custom-print tbody tr {
  font-size: 25px;
  border: 1px solid #000 !important;
  padding: 10px;
  height: 20px;
}

.user-list-table td,
th {
  border: 1px solid #ecf0f3 !important;
}

.borderTableims {
  margin-bottom: 2px !important;
}

.borderTableims.table-head-custom thead td {
  color: #464e5f;
  font-weight: 400 !important;
}

.borderTableims.table-head-custom thead tr th {
  color: #000 !important;
  font-weight: 600 !important;
}

.borderTableims.table-head-custom-print thead td {
  color: #000;
  font-size: 25px;
  font-weight: 900 !important;
}

.borderTableims.table-head-custom-print tbody tr td {
  color: #000;
  font-size: 25px;
  border: 1px solid #000 !important;
  font-weight: 900 !important;
}

.table.table-head-custom-print thead th {
  color: #000;
  font-size: 25px;
  font-weight: 900;
}

/* daily basis */
.dailybasistable.table-head-custom thead th,
.dailybasistable.table-head-custom thead tr {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border: 1px solid #000 !important;
  padding: 8px;
}

.dailybasistable.table-head-custom tbody th,
.dailybasistable.table-head-custom tbody tr td {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border: 1px solid #000 !important;
  padding: 8px;
}

.dailybasistable.table-head-custom-print thead tr th,
.dailybasistable.table-head-custom-print thead tr {
  font-size: 25px;
  border: 1px solid #000 !important;
  padding: 10px;
  height: 20px;
}

.dailybasistable.table-head-custom-print tbody th,
.dailybasistable.table-head-custom-print tbody tr {
  font-size: 25px;
  border: 1px solid #000 !important;
  padding: 10px;
  height: 20px;
}

.dailybasistable {
  margin-bottom: 2px !important;
}

.dailybasistable.table-head-custom thead td {
  color: #000 !important;
}

.dailybasistable.table-head-custom thead th {
  color: #000 !important;
  font-weight: 600;
}

.dailybasistable.table-head-custom-print thead td {
  color: #000 !important;
  font-size: 25px;
  font-weight: 900 !important;
}

.dailybasistable.table-head-custom-print tbody tr td {
  color: #000 !important;
  font-size: 25px;
  border: 1px solid #000 !important;
  font-weight: 900 !important;
}

.table> :not(:first-child) {
  border-top: none !important;
}

/* daily basis */
.paddingremoveLeftCM {
  padding-left: 0px !important;
}

.paddingremove {
  padding-right: 1px !important;
  padding-left: 1px !important;
}

.array.arytext {
  text-align: right !important;
  position: absolute;
}

.arytext {
  font-weight: 800;
}

button.arrayButton.btn.btn-link {
  padding: 0;
  text-decoration: none;
}

.dailysalesfont {
  font-family: fantasy;
}

.moneyacheived {
  color: #975100;
}

.postisionAb {
  position: absolute;
  top: 26px;
  right: 8px;
}

.positionAssigned {
  position: relative;
}

table.pvtTable tbody tr th,
table.pvtTable thead tr th {
  background-color: #ebf0f8 !important;
  border: 1px solid #c8d4e3 !important;
  font-size: 8pt !important;
  padding: 5px !important;
}

.cuswidth3 {
  width: 350px;
  text-align: center !important;
  justify-content: center !important;
  margin: auto;
}

.googleChart {
  display: block;
  margin: auto;
}

.location {
  color: #94520f;
}

.monthlyPrdIdeal {
  position: absolute;
  top: 36px;
  right: 22px;
  border-radius: 5px;
  background-color: #ffc000;
  padding: 5px;
  color: white;
}

.monthlyPrdIdeal p {
  margin-bottom: 0;
}

.bolderhide {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border: 1px solid #00000000 !important;
  padding: 8px;
}

.MTDActPrd {
  position: absolute;
  top: 90px;
  right: 16px;
  /* background-color: bisque; */
  /* background-color: rgb(255, 99, 132); */
  background-color: #ed7d31;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 0px;
  font-weight: 600;
  color: white;
}

.MTDActPrdData {
  position: absolute;
  top: 35px;
  right: 16px;
  /* background-color: bisque; */
  /* background-color: rgb(255, 99, 132); */
  background-color: #ed7d31;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 0px;
  font-weight: 600;
  color: white;
}

.StockPosition {
  position: absolute;
  top: 160px;
  right: 126px;
  /* background-color: rgb(75, 192, 192); */
  background-color: #548235;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.AVGSales {
  position: absolute;
  top: 161px;
  right: 16px;
  border: 2px solid rgb(255, 99, 132);
  padding: 3px;
  border-radius: 5px;
  background-color: white;
}

.locationMother {
  /* position: relative !important; */
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.salesMonitoringTable table {
  border-collapse: collapse;
  width: 100%;
  font-size: 10px;
}

.salesMonitoringTable thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.salesMonitoringTable thead th {
  text-align: center;
}

.salesMonitoringTable td {
  border: 1px solid #ddd;
  padding: 2px;
  text-align: left;
}

.salesMonitoringTable th {
  /* background-color: #f2f2f2; */
  background-color: black;
  color: white;
}

.salesMonitoringTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.salesMonitoringTable .pagination {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.salesMonitoringTable .pagination a {
  color: white !important;
  float: left;
  padding: 2px 12px;
  text-decoration: none;
  transition: background-color 0.3s;
  background-color: #857f99;
  margin-right: 2px;
  border-radius: 5px;
}

.salesMonitoringTable .pagination a.active {
  background-color: lightseagreen;
  border-radius: 5px;
  color: white !important;
  font-weight: 600;
}

.salesMonitoringTable .pagination a:hover:not(.active) {
  background-color: #9176e8;
  border-radius: 5px;
}

.salesMonitoringTable {
  overflow: auto;
  padding: 0px 5px;
}

.salesHeight {
  height: 500px;
}

.scrollChart {
  width: 400px;
  overflow-x: scroll;
}

.hrozontalscroll {
  /* height: 500px; */
  width: 5000px;
}

.arrow-down-icon {
  color: red;
}

.arrow-up-icon {
  color: green;
  margin-right: 8px;
}

.form-group {
  margin-bottom: 0 !important;
}

.asideHeading {
  text-transform: uppercase;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.07em;
}

.locationMother {
  position: relative !important;
}

.locationPosition {
  position: absolute;
}

.chart-container::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.chart-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 6px;
}

.chart-container::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

.chart-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 6px;
}

.txtwidth {
  font-weight: 550;
  margin-bottom: 4px;
}

.text-4th {
  font-size: 25px;
  font-weight: 200;
  text-align: center;
  letter-spacing: 0;
  font-family: fantasy;
}

.updatedC {
  color: #7d4b10 !important;
}

.moneyacheivedC {
  color: #17b169 !important;
}

.changeC {
  color: #0b8794;
}

.updaterdtColor {
  color: rgb(151, 110, 63) !important;
}

.vertical_bar_bottom_color_1 {
  color: #7d4b10;
}

.vertical_bar_bottom_color_2 {
  color: #0b8794;
}

.vertical_bar_bottom2 {
  border: 1px solid #0b8794;
}

.clickHere {
  font-size: 11px !important;
  font-weight: 300;
  font-family: "Poppins";
}

.dropdownArrow {
  font-size: 14px !important;
  margin-top: 2px;
}

.wpassed {
  color: #8E7A7B;
  font-size: 25px;
  font-weight: 200;
  text-align: center;
  letter-spacing: 0;
  font-family: fantasy;
}

.wpassed2 {
  color: #8E7A7B;
  font-size: 20px;
  font-weight: 200;
  text-align: center;
  letter-spacing: 0;
  font-family: fantasy;
}

.pdfBGC {
  background-color: #00a154 !important;
}

.pdfBGC2 {
  background-color: #bf9000 !important;
}

.pdfFontC {
  font-weight: 700;
  color: white !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffc000;
}

.pdfFont2C {
  font-weight: 700;
  color: white !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #67a2d6;
}

.txtsize {
  font-size: 15px !important;
  font-weight: 500;
}

i.fas.fa-arrow-down {
  color: red;
}

i.fas.fa-arrow-up {
  color: green;
}

.redArrow i {
  display: inline-block;
}

.reportDataTable table tr td {
  font-size: 10px !important;
}

.reportDataTable table tr th {
  font-size: 12px !important;
}

.borderTableims.table-head-custom tbody tr th,
.borderTableims.table-head-custom tbody tr td {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border: 1px solid #000 !important;
  padding: 2px;
}

.modal-header {
  padding: 0px !important
}

/* 
print */
@media print {
  .print-table {
    /* Add your styles for printing here */
    /* For example: */
    font-size: 12px;
    border: 1px solid black;
  }
}

.modal-container-fluid .modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container-fluid .modal-content {
  width: 100%;
  max-width: 100%;
  border-radius: 0;
}

.grandtotal {
  font-size: 15px;
  font-weight: 400;
}

.button-010 {
  background: linear-gradient(to bottom right, #80e6bb, #32c2bd);
  border: 0;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 2.2;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow 0.2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.fullcardbg {
  background-color: #FBF8FA;
}

.backcolor {
  background: #D8D8E0;
}

.backcolor1 {
  background: #afc7df;
}

.backcolor2 {
  background: #9ECED5;
}

.cutom-print-btn {
  background-color: #E3E1D3 !important;
}

.button-621 {
  background: linear-gradient(to bottom right, #484787, #059c97);
  border: 0;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.2;
  outline: transparent;
  padding: 0 1.4rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.bgcolor {
  background-color: #88ACB4;
  color: #ffff;
}

.save-btn-grad {
  background-image: linear-gradient(to right,
      #314755 0%,
      #26a0da 51%,
      #314755 100%);
  /* margin: 10px; */
  padding: 5px 10px;
  text-align: center;
  transition: 0.5s;
  font-weight: 500;
  background-size: 200% auto;
  color: white !important;
  box-shadow: 0 0 20px #eee;
  border-radius: 12px;
  display: block;
  border: none;
  font-size: 14px;
  line-height: 1.5;
}

.save-btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.CumulativeSalesReport {
  background: #47A992;
  padding: 20px 0px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  margin-right: 5px;
  font-size: 16px;
}

.CumulativeSalesReport:hover {
  background: #32766A;
}

.Group-A {
  background: #927eda;
  padding: 20px 0px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.Group-A:hover {
  background: #7C6CF2;
}

.backgroundCM {
  background: #fff;
}

.netstocktd {
  font-weight: 600 !important;
  background: #c2c3c4;
}

.practice-sales-monitoring-container {
  margin-left: 0;
  padding-left: 0;
}



/* CSS */
.button-87 {
  margin: 10px;
  padding: 8px 26px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 15px;
  display: block;
  border: 0px;
  font-weight: 700;
  background-image: linear-gradient(45deg, #116165 0%, #40B4E5 51%, #116165 100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-87:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.button-87:active {
  transform: scale(0.95);
}

/* dashboard */
.btn-grad {
  background-image: linear-gradient(to right, #16222A 0%, #3A6073 51%, #16222A 100%);
  margin: 10px;
  padding: 25px 5px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  cursor: pointer;
}

.btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.btn-grad2 {
  background-image: linear-gradient(to right, #4CB8C4 0%, #3CD3AD 51%, #4CB8C4 100%);
  margin: 10px;
  padding: 25px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  cursor: pointer;
}

.btn-grad2:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.btn-grad i,
.btn-grad2 i {
  color: white;
}


.btn-grad3 {
  background-image: linear-gradient(to right, #f8ab63 0%, #f8ab63 51%, #de7606 100%);
  margin: 10px;
  padding: 25px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  cursor: pointer;
}

.btn-grad3:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.btn-grad i,
.btn-grad3 i {
  color: white;
}

/* dashboard */

.chart-container {
  width: 100%;
  overflow-x: auto;
}


.pagination {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.pagination a {
  color: white !important;
  float: left;
  padding: 2px 12px;
  text-decoration: none;
  transition: background-color 0.3s;
  background-color: #857f99;
  margin-right: 2px;
  border-radius: 5px;
}

.pagination a.active {
  background-color: lightseagreen;
  border-radius: 5px;
  color: white !important;
  font-weight: 600;
}

.pagination a:hover:not(.active) {
  background-color: #9176e8;
  border-radius: 5px;
}

.dark-background {
  background-color: rgb(183, 208, 238);
  /* Replace #333 with the color of your choice */
  color: #fff !important;
  /* Set the text color to make it visible on the dark background */
  font-weight: 600 !important;
}

.nettotalbg {
  background: #fcffdc;
  font-weight: 600 !important;
}

.overandunder {
  background-color: red;
}

.monthlyPrdIdealWeight {
  border-radius: 5px;
  background-color: #1bc5bd;
  padding: 5px;
  color: white;
  width: 150px;
  margin-top: 2px;
  margin-left: 5px;
}

/* ABL CM Analysis css start */

.column-container {
  display: flex;
  flex-direction: column;
}

.column-item {
  margin: 0px;
}

.button-122 {
  background: linear-gradient(to bottom right, #19195D, #19195D);
  border: 0;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.2;
  outline: transparent;
  padding: 0 1.4rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-123 {
  background: linear-gradient(to bottom right, rgb(93, 98, 181), rgb(93, 98, 181));
  border: 0;
  border-radius: 12px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 4.2;
  outline: transparent;
  padding: 0 1.4rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  border: solid 1px rgb(73, 155, 222);
}

.button-33 {
  background: linear-gradient(to bottom right, rgba(242, 114, 111, 0.9), rgba(242, 114, 111, 0.9));
  border: 0;
  border-radius: 6px;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.9;
  outline: transparent;
  padding: 0 4.0rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  border: solid 1px rgb(73, 155, 222);
  position: absolute;
  left: 60%;
}

.button-124 {
  background: linear-gradient(to bottom right, #43ccfe, #43ccfe);
  border: 0;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 4.2;
  outline: transparent;
  padding: 0 1.8rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  border: solid 1px rgb(44, 123, 187);
}

.button-125 {
  background: linear-gradient(to bottom right, rgba(242, 114, 111, 0.9), rgba(242, 114, 111, 0.9));
  border: 0;
  border-radius: 12px;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 4.2;
  outline: transparent;
  padding: 0 2.9rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  border: solid 1px rgb(44, 123, 187);
}

.tableheadingCM .CM0 {
  background-color: rgb(72, 66, 250) !important;
}

.tableheadingCM .CM1 {
  background-color: rgb(250, 121, 23) !important;
}

.tableheadingCM .CM2 {
  background-color: rgba(242, 114, 111, 0.9) !important;
}

.tableheadingCM .CM3 {
  background-color: #43ccfe !important;
}

td.CM0.CM1FixedWidth {
  background-color: rgb(72, 66, 250) !important;
}

td.CM1.CM1FixedWidth {
  background-color: rgb(250, 121, 23) !important;
}

td.CM2.CM1FixedWidth {
  background-color: rgba(242, 114, 111, 0.9) !important;
  padding: 2px !important;
}

td.CM3.CM1FixedWidth {
  background-color: #43ccfe;
  padding: 2px !important;
}

td.CM2.CM1FixedWidth .CM1Sub2 {
  /* width: 90px; */
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.button-126 {
  background: linear-gradient(to bottom right, #02121b, #02121b);
  border: 0;
  border-radius: 12px;
  color: black;
  cursor: pointer;
  display: block !important;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.2;
  outline: transparent;
  padding: 0 1.14rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-127 {
  background: linear-gradient(to bottom right, #43ccfe, #43ccfe);
  border: 0;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.9;
  outline: transparent;
  padding: 0 1.4rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  border: solid 1px rgb(73, 155, 222);
}

.CM1Sub1 {
  background-color: black;
  color: white;
  padding: 0 1.00rem;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.0;
  white-space: nowrap;
  border: 2px solid #5583b5;

}

.CM1Sub2 {
  background-color: rgb(200, 233, 247);
  color: black;
  padding: 0 1.44rem;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 500;
  line-height: 2.0;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid #5583b5;
}

.CM1Sub2Red {
  background-color: rgb(255, 13, 13);
  color: rgb(255, 255, 255);
  padding: 0 1.44rem;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 500;
  line-height: 2.0;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid #5583b5;
}

.CM1Sub2Yellow {
  background-color: rgb(212, 199, 9);
  color: rgb(255, 255, 255);
  padding: 0 1.44rem;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 500;
  line-height: 2.0;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid #5583b5;
}


.CM1Sub4 {
  background-color: #ffff;
  border: 2px solid #fcda41;
  color: black;
  padding: 0 0.2rem;
  padding-top: 70px;
  padding-bottom: 70px;
  border-radius: 12px;
  font-size: 13px;
  font-weight: 500;
  line-height: 2.0;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.CM1Sub2.CM1FixedWidth {
  width: 80px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
}

span.CM1Sub2 {
  /* width: 90px; */
}

.CM1Sub2Name {
  padding: 10px !important;
}

.user-list-table td,
th {
  border: 0px solid #ecf0f3 !important;
}

.allfigure {
  font-size: 10px !important;
  font-weight: 600;
}

.tabletopdiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.marginremoveCM {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.tooltip {
  position: absolute;
  background-color: #f8905a;
  padding: 6px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 999;
  /* Make sure this value is higher than other elements */
  display: none;
  /* Hide it by default */
}

.cm4thchartmarginremove {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.demoStack svg {
  overflow: visible;
}

/* ABL CM Analysis css end */



.threecardtop {
  margin-top: 60px !important;
}

.alllfigright {
  position: absolute;
  right: 0;
  margin-right: 14px !important;
  font-weight: 500;
  font-size: 10px;
}

/* colored table traffic */

/* Style the flex container */
.colored .flex-container {
  display: flex;
  justify-content: center;
}

/* Style each column */
.colored .flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Define the colors for each section and make them circular */
.colored .circle {
  width: 70px;
  /* Set the width and height to control the circle size */
  height: 70px;
  border-radius: 0%;
  /* Create a circular shape */
  text-align: center;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

/* Apply background colors */
.colored .yellow {
  background-color: yellow;
  color: black;
}

.colored .red {
  background-color: red;
  color: white;
}

.colored .green {
  background-color: green;
  color: white;
}

.valuetitlesize {
  font-size: 10px !important;
  color: #ffff;
}

.bgcolorwhite {
  background: #ffff;
}

/* colored table traffic */

.button-1001 {
  background: linear-gradient(to bottom right, rgb(255, 255, 255), rgb(255, 255, 255));
  border: 0;
  border-radius: 12px;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 4.0;
  font-size: 11px;
  outline: transparent;
  padding: 0 1.4rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  width: 140px;
}

.button-1002 {
  background: linear-gradient(to bottom right, #226b1b, #226b1b);
  border: 0;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 4.0;
  font-size: 11px;
  outline: transparent;
  padding: 0 1.4rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  width: 140px;
}

.button-1003 {
  background: linear-gradient(to bottom right, rgb(248, 144, 90), rgb(248, 144, 90));
  border: 0;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 4.0;
  font-size: 11px;
  outline: transparent;
  padding: 0 1.4rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  width: 140px;
}

.button-1004 {
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(180, 225, 235, 1) 0%, rgba(129, 129, 204, 1) 93%);
  border: 0;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 4.0;
  font-size: 11px;
  outline: transparent;
  padding: 0 1.4rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  width: 140px;
}

.cmCard {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f5eaea;
  background-clip: border-box;
  border: 1px solid #ECF0F3;
  border-radius: 0.42rem;
}

.cm4thlegand {
  position: absolute;
}




/*Traffic Light*/

.btnspanbody {
  position: absolute;
  align-items: center;
  justify-content: center;
  text-align: center;
  left: 40%;
  bottom: 31%;
  font-size: 16px;
  font-weight: 600;
}

.btnspanbodyGreen {
  position: absolute;
  align-items: center;
  justify-content: center;
  text-align: center;
  left: 34%;
  bottom: 30%;
  font-size: 16px;
  font-weight: 600;
}

.main {
  margin-top: 10px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.switch {
  display: block;
  position: relative;
  background-color: black;
  width: 70px;
  height: 70px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2), 0 0 1px 2px black, inset 0 2px 2px -2px white, inset 0 0 2px 5px #47434c, inset 0 0 2px 22px black;
  border-radius: 50%;
  padding: 20px;
  margin: 5px;
}

.switch input {
  display: none;
}

.switch input:checked+.button .light {
  animation: flicker 0.2s infinite 0.3s;
}

.switch input:checked+.button .shine {
  opacity: 1;
}

.switch input:checked+.button .shadow {
  opacity: 0;
}

.switch .button {
  transition: all 0.3s cubic-bezier(1, 0, 1, 1);
  background-color: #9b0621;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  position: relative;
  left: -0.95em;
  top: -0.90em;
  cursor: pointer;

}

.switch .light {
  opacity: 0;
  animation: light-off 1s;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(#ffc97e, #ff1818 40%, transparent 70%);
}

.switch .dots {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(transparent 30%, rgba(101, 0, 0, 0.7) 70%);
  background-size: 10px 10px;
  border-radius: 50%;
}

@keyframes flicker {
  0% {
    opacity: 1;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@keyframes light-off {
  0% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }
}

.switch1 {
  display: block;
  position: relative;
  background-color: black;
  width: 70px;
  height: 70px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2), 0 0 1px 2px black, inset 0 2px 2px -2px white, inset 0 0 2px 5px #47434c, inset 0 0 2px 22px black;
  border-radius: 50%;
  padding: 20px;
  margin: 5px;
}

.switch1 input {
  display: none;
}

.switch1 input:checked+.button .light {
  animation: flicker 0.2s infinite 0.3s;
}

.switch1 input:checked+.button .shine {
  opacity: 1;
}

.switch1 input:checked+.button .shadow {
  opacity: 0;
}

.switch1 .button {
  transition: all 0.3s cubic-bezier(1, 0, 1, 1);
  background-color: #949207;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  position: relative;
  left: -0.95em;
  top: -0.90em;
  cursor: pointer;
}

.switch1 .light {
  opacity: 0;
  animation: light-off 1s;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(#f6ff7e, #eeff00 40%, transparent 70%);
}

.switch1 .dots {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(transparent 30%, rgba(91, 101, 0, 0.7) 70%);
  background-size: 10px 10px;
  border-radius: 50%;
}

.switch2 {
  display: block;
  position: relative;
  background-color: black;
  width: 70px;
  height: 70px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2), 0 0 1px 2px black, inset 0 2px 2px -2px white, inset 0 0 2px 5px #47434c, inset 0 0 2px 22px black;
  border-radius: 50%;
  padding: 20px;
  margin: 5px;
}

.switch2 input {
  display: none;
}

.switch2 input:checked+.button .light {
  animation: flicker 0.2s infinite 0.3s;
}

.switch2 input:checked+.button .shine {
  opacity: 1;
}

.switch2 input:checked+.button .shadow {
  opacity: 0;
}

.switch2 .button {
  transition: all 0.3s cubic-bezier(1, 0, 1, 1);
  background-color: #2f9407;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  position: relative;
  left: -0.95em;
  top: -0.90em;
  cursor: pointer;
}

.switch2 .light {
  opacity: 0;
  animation: light-off 1s;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(#f6ff7e, #00ff00 40%, transparent 70%);
}

.switch2 .dots {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(transparent 30%, rgba(0, 101, 13, 0.7) 70%);
  background-size: 10px 10px;
  border-radius: 50%;
}

/*Traffic Light*/

td.CM1.CM1FixedWidth {
  padding: 0px !important;
}

/* receipeModal */

/* Add this CSS for connecting lines */
.flowchart {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.step {
  display: flex;
  align-items: center;
  /* margin-bottom: 20px; */
}

.step span {
  width: 30px;
  height: 30px;
  background-color: #007bff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
}

.step p {
  margin: 0;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Style the lines as dotted */
.line {
  width: 0;
  border-left: 3px dotted #007bff;
  height: 12px;
  margin: 0 auto;
  /* margin-bottom: 10px; */
  padding-left: 14px;
  position: relative;
  right: 47% !important;
}

/* Style the last step without a line */
.step:last-child .line {
  display: none;
}




/* receipeModal */

/* .modal{
  display: block !important; 
} */

/* Important part */
/* .modal-dialog{
  overflow-y: initial !important
}
.modal-body{
  height: 80vh;
  overflow-y: auto;
} */
.customModal {
  height: 80vh;
  overflow-y: auto;
}

.core-text {
  display: inline-block;
  white-space: nowrap;
  transform: rotate(-90deg);
  /* Rotate the text 90 degrees counterclockwise */
  transform-origin: 50% 50%;
  /* Set the rotation origin to the center of the element */
}

.recepieta.table-head-custom tbody tr th,
.recepieta.table-head-custom tbody tr td {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border: 1px solid #000 !important;
  padding: 2px;
  font-size: 10px;
}

.recepieta tr th {
  font-size: 10px !important;
}

.tooltip {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 12px;
  display: none;
}

.zindexmodified {
  z-index: 2 !important;
}

#kt_header {
  z-index: 2 !important;
}

.p-0.m-0.dropdown-menu-right.dropdown-menu-anim.dropdown-menu-top-unround.dropdown-menu-xl.dropdown-menu.show.dropdown-menu-right {
  z-index: 1 !important;
  margin-top: 100px !important;

}

.jvectormap-container {
  height: 700px;
  /* Set the desired height */
}






/* map css started */

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 123%;
  margin-left: -180px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  padding-left: 10px !important;
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 100%;
  max-height: 100vh;
  min-height: 99vh;
  text-align: center;
  border-radius: 10px;
}

.modal-content-range {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 600px;
  max-height: 100vh;
  text-align: center;
  border-radius: 10px;
}


@media only screen and (min-width: 800px) {
  .modal-content {
    width: 80%;
  }

  .modal-content-range {
    width: 600px;
  }
}

@media only screen and (max-width: 800px) {
  .modal-content {
    width: 95%;
  }

  .modal-content-range {
    width: 95%;
  }
}

.table-container::-webkit-scrollbar {
  width: 10px;
}

.table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-container::-webkit-scrollbar-thumb {
  background: #888;
}

.close {
  color: #182C61;
  text-align: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  opacity: 0.6;
}

.close:hover {
  color: black;
}

.table-container {
  overflow-x: auto;
/*   margin-right: 20px; */

  margin-left: 30px !important;
}

.territory-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
}

.territory-table th,
.territory-table td {
  padding: 12px;
  text-align: left;
  border: 1px solid black;
}

.territory-table th {
  background-color: #f0f0f0;
}

.territory-tr tr {
  border: 1px solid black !important;
}

.nav {
  text-align: center;
}

.nav a {
  margin: 10px;
}

@media only screen and (min-width: 800px) {
  #divisionMap {
    height: 80vh;
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  #divisionMap {
    height: 65vh;
    width: 100%;
  }
}

#divisionMap g {
  cursor: pointer;
}

#divisionMap .division {
  transition: opacity 0.1s linear;
}

#divisionMap .region {
  transition: opacity 0.1s linear;
}

#divisionText {
  pointer-events: none;
}

#divisionMap.territoryWrapper path:not(.territory) {
  pointer-events: none;
}

#divisionMap .territory {
  transition: 0.1s all;
}

@media only screen and (min-width: 800px) {
  #territoryMap {
    height: 80vh;
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  #territoryMap {
    height: 50vh;
    width: 100%;
  }
}

#territoryMap g {
  cursor: pointer;
}

.switch-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* The switch - the box around the slider */
.switchMap {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 26px;
  margin: auto 10px;
}

/* Hide default HTML checkbox */
.switchMap input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #0D6EFD;
}

input:focus+.slider {
  box-shadow: 0 0 1px #0D6EFD;
}

input:checked+.slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.list-container {
  max-height: 300px;
  overflow-y: scroll;
}

.list-container::-webkit-scrollbar {
  width: 8px;
}

.list-container::-webkit-scrollbar-track {
  background: #f2f2f2;
  border-radius: 5px;
}

.list-container::-webkit-scrollbar-thumb {
  background: #182C61;
  border-radius: 5px;
}

.range-table {
  font-size: 14px;
  position: relative;
  /*   z-index: 10; */
}

.range-table td,
th {
  padding: 5px !important;
}

.list-container ul.list-group {
  clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
  background-color: #182C61;
  color: #f1f1f1;
}

.list-container ul.list-group li {
  padding-left: 21%;
  padding-right: 21%;
}

.list-container .list-name {
  padding-right: 5px;
}

.list-container ul li {
  padding: 5px;
  border-bottom: 1px solid #e0e0e0;
  list-style-type: none;

}

.list-container ul.list-group li {
  white-space: nowrap;
  overflow-x: scroll;
}

.list-container ul.list-group li::-webkit-scrollbar {
  height: 0px;
}

.list-container .list-name {
  white-space: nowrap;
  overflow-x: scroll;
}

.list-container .list-name::-webkit-scrollbar {
  height: 5px;
}

.list-container .list-name::-webkit-scrollbar-track {
  border-radius: 5px;
}

.list-container .list-name::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

.last-li {
  background-color: #182C61;
  clip-path: polygon(20% 0, 50% 100%, 80% 0);
}

svg circle {
  cursor: pointer;
}

.bg-blue {
  background-color: #182C61;
}

input {
  outline: none;
}

.react-datepicker__input-container input {
  border-radius: 0.25rem;
  border: 0;
  padding: 6px 10px 5px 30px;
}

.react-datepicker__calendar-icon {
  top: 49%;
  transform: translatey(-50%);
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #007bff;
}

.edit-icon {
  color: #0D6EFD;
  cursor: pointer;
}

.edit-icon:hover {
  color: #182C61;
}

.react-datepicker-wrapper input {
  width: 100%;
}

.map-nav-buttons {
  position: absolute;
  right: -10px;
  top: -3px;
}

.map-zoom-buttons a {
  font-size: 20px;
}

.map-zoom-buttons .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0) !important;
}

.map-nav-buttons a {
  font-size: 20px;
}

.react-transform-wrapper {
  width: 100% !important;
  margin-top: 15px !important;
}

.react-transform-component {
  width: 100% !important;
}

.top_card3-map {
  background: #0b8794;
}

.main-sales-com {
  margin-top: -20px !important;

}

.btn-map-size {
  padding: 10px !important;
  margin-bottom: -30px;
}

.closs-icon {
  padding-right: 30px;
}

.region-map-m {
  padding-top: -40px !important;
  margin-top: -40px;
}

.region-map-navBtn {
  padding: 10px !important;
  background-color: rebeccapurple;
}



.list-name li {
  font-size: 10px;
  height: 26px;
}

.list-group li {
  font-size: 10px;
}

.carosel-div-main {
  background-color: white;
  width: 100%;
  height: 95%;
}

.btn-nextpage-caroser {
  color: rebeccapurple;
  background-color: wheat;

}

.dailyImsTitle {
  width: 30%;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 30px;
}


.continue-stop-btn {
  margin-top: 4px !important;
  background: linear-gradient(to bottom right, rgb(248, 144, 90), rgb(248, 144, 90));
  border: 0;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 4.0;
  font-size: 11px;
  outline: transparent;
  padding: 0 1.4rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  width: 40px;
}

.button-1002-continur {
  background-color: #007bff;
  padding: 10px;
  border-radius: 10px;
  color: white;
}

.ims-division-wise-report {
  background-color: white;
}

.redColorStyle {
  color: red !important;
}

.greenColorStyle {
  color: green !important;
  font-size: 18px;
}

.text-center-style {
  text-align: center !important;
}


.export-for-excel {
  margin-left: 80%;
}

.noAvailableata {
  width: 250px;
  margin: auto;
  padding-bottom: 30px;
  font-size: large;
}

.rolling-stop-continue {
  margin-top: 11px !important;
}

.main-carosal-container {
  margin-top: 10px !important;
}

.header-working th {
  background-color: green !important;
  color: whitesmoke;
}

.territory-table thead tr th {
  border: 1px solid black !important;
}

.heigtsetInHeader {
  width: 80px !important;
}

.header-working tr {

  width: 80px !important;
  text-align: center !important;

}

.forFontstyle tr th {
  width: 80px !important;
  text-align: center !important;
}

.font-weights{
   font-size: large !important;
}
.btn-page-all{
  width: 90% !important;
  margin: auto;
  margin-left: 12% !important;
}
.btn-page-all div{
  min-height: 100px !important;
}

.modal-position{
  margin-left: 400px !important;
}

@media screen and (max-width: 768px) {
  .modal-content {
    width: 100% !important;
    padding-left: 110px !important;

 
  }

         .float-right {
            padding-right: 110px !important;
            padding-top: 1px !important;
            color:  wheat;
            position:  fixed !important;
         }
  
 
}
 


@media screen and (max-width: 768px) {
  .territory-table {
    width: 100%;
  }

  .territory-table th,
  .territory-table td {
    white-space: nowrap;
  }
  .table-container{
    padding-left: 10px !important;
    }
 
}

.root-month{
  width: 200px !important;
  margin: auto !important;
}

.bgColor-Sub-root{
  background-color: purple !important;
  padding-bottom: 5px !important;
}

.modal-cls-btn{
  margin-top: -30px;
 }

/* CSS for making the modal responsive */
/* CSS to center the modal on mobile devices  
 CSS to center the modal on mobile devices */
/* @media (max-width: 767px) {
  .modal-dialog {
    margin: auto !important;
    padding-left: 90px !important;
    margin-left: 1px !important;
    width: 100% !important;
  
 
  }
 
}
 */
 



 